import 'Assets/CSS/CustomInput.css';
import { useMemo } from 'react';


const CustomInput = props => {
    let { className, value, ...otherProps } = props

    const computedClassName = useMemo(() => !value ? `emptyinput ${className}` : className, [className, value]) 

    return <input 
        className={computedClassName} 
        value={value} 
        {...otherProps}
    />
}
export default CustomInput