import { useMemo } from "react";
import CustomInput from "./CustomInput";

function isDateFormat(str) {
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  return regex.test(str);
}
function parseDateWithoutTime(str) {
    const [year, month, day] = str.split('-').map(Number);
    return new Date(year, month - 1, day);
  }
function hasMidnightTime(date) {
  return (
    date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0
  );
}

const DatetimeField = (props) => {
  let { value, ...otherProps } = props;

  const renderedValue = useMemo(() => {
    if (value) {
      try {
        let d;
        if (isDateFormat(value)) {
            d = parseDateWithoutTime(value);
        } else {
            d = new Date(value);
        }
        if (hasMidnightTime(d)) {
          return d.toLocaleDateString();
        } else {
          return d.toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          });
        }
      } catch (e) {
        console.warn("failed to render date", value, e);
      }
    }
  }, [value]);

  const handleOnChange = () => {};

  return (
    <CustomInput
      type={"text"}
      value={renderedValue??''}
      onChange={handleOnChange}
      {...otherProps}
    />
  );
};
export default DatetimeField;
